<template>
  <div class="list-card">
    <div class="list-card-right">
      <div class="list-card-overlay" />
      <div class="list-card-header">
        <div class="d-flex align-items-center">
          <i v-if="attrs['icon']" :class="attrs['icon']" class="fa-2x" />
          <h5 :class="attrs['icon'] && 'pl-2'">{{ attrs['title'] }}</h5>
        </div>
        <div v-if="attrs['newAddActive']">
          <span v-if="attrs['totalActive']">Toplam Kayıt : {{ attrs['total'] }}</span>
          <b-button v-if="attrs['newAddActive']" squared variant="success" class="ml-4" @click="$emit('handlerAddNew', false)">
            <i class="fad fa-plus" /> YENİ KAYIT
          </b-button>
          <b-button v-if="attrs['backActive']" squared variant="secondary" class="ml-4" @click="$emit('handlerBackTo', true)">
            <i class="fad fa-angle-left" /> GERİ
          </b-button>
        </div>
        <div v-else class="d-flex align-items-center">
          <span class="pr-3 font-weight-light" v-if="attrs['totalActive']">Toplam : {{ attrs['total'] }}</span>
          <slot name="headerAction" />
        </div>
      </div>
      <div class="list-card-body">
        <b-overlay id="overlay-background" :show="attrs['show']" variant="light" :opacity="0.8" blur="2px" rounded="sm">
          <slot name="body" />
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from '@/libs/utils';
import { ref, defineComponent, useAttrs } from '@vue/composition-api';

export default defineComponent({
  inheritAttrs: false,
  setup() {
    const expo = {};
    const { router } = useRouter();
    expo.attrs = useAttrs();
    expo.search = ref(null);

    return { ...expo, router };
  },
});
</script>

<style lang="scss" scoped></style>
